.header_wrapper{
    background: #1c1751;
    min-height: 65px;
    padding: 10px 12px;
}

.header_wrapper .header_left p{
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.header_wrapper .header_right{
    color: white;
}

.header_wrapper .header_right .header_search_container input{
    background: #120731;
    border-radius: 4px;
    padding: 8px 12px;
    outline: none;
    font-size: 14px;
    text-align: left;
    color: white;
    border: none;
    padding-left: 30px;
    max-width: 200px;
}

.header_wrapper .header_right .profile_name p{
    font-size: 18px;
    font-weight: bold;
} 

.header_wrapper .header_right .profile_icon .user_icon{
    font-size: 30px;
}

.header_wrapper .header_right .header_search_container {
    position: relative;
}

.header_wrapper .header_right .header_search_container .search_icon {
    position: absolute;
    top: 10px;
    left: 6px;
    font-size: 18px;
}


.dashboard_header_filter button{
    color: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    background: #120731;
    padding: 8px 12px;
    border: none;
    min-width: 100px;
}
.dashboard_header_filter button:hover{
    background-color: #1c1751;
    cursor: pointer;
}