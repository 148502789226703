* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.flex-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.p10 {
  padding: 10px;
}

.p12 {
  padding: 12px;
}

.p20 {
  padding: 20px;
}

.p24 {
  padding: 24px;
}

.p30 {
  padding: 30px;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.width_98_auto {
  margin: 20px auto;
  width: 98%;
}

.flex-gap {
  gap: 16px;
}

.flex-gap-extended {
  gap: 30px;
}

text-left {
  text-align: left;
}

.justify-end {
  justify-content: flex-end;
}

.flex-gap-extra-extended {
  gap: 100px;
}

progress[value]::-webkit-progress-value {
  border: 0px solid white;
  background: #835bef;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.max-width_60 {
  max-width: 60px;
}

.min_width_180 {
  min-width: 180px;
}

.min_width_900 {
  min-width: 900px !important;
}

.flex-column {
  flex-direction: column;
}

.block {
  display: block;
}

.none {
  display: none;
}