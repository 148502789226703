.sidebar {
    background: #1c1751;
    top: 0;
    left: 0;
    width: 255px;
    height: 100%;
    padding: 15px;
    text-align: center;
    z-index: 2;
    overflow-y: scroll;
}

.logo {
    display: flex;
    align-items: center;
    padding: 0px 20px;
}

.sidebar_list {
    margin-top: 40px;
}

.logo h2 {
    display: inline;
    color: white;
    font-size: 24px;
}

.logo img {
    margin-top: 2px;
    width: 30px;
    color: white;
}

.sidebar li a,
.submenu_wrapper {
    font-size: 16px;
    text-decoration: none;
    color: #c2c3c4;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 10px 20px;
    width: 100%;
}

.sidebar li .submenu_wrapper:hover {
    cursor: pointer;
}

.sidebar ul li .submenu {
    width: 100%;
}

.sidebar ul li .submenu .submenu_list {
    padding: 5px 0px;
    gap: 10px;
}

.sidebar ul li .submenu ul li a {
    font-size: 15px;
    text-decoration: none;
    color: #c2c3c4;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding-left: 50px;
}

.sidebar li p .submenu_icon {
    margin-bottom: -3px;
    margin-left: 3px;
    font-size: 20px;
}

.sidebar li a .nav_icon {
    margin-right: 10px;
}

.sidebar li p .nav_icon {
    margin-right: 10px;
}

.sidebar li a:hover {
    text-decoration: none;
    background-color: #835bef66;
    color: white;
}

.sidebar li p:hover {
    text-decoration: none;
    background-color: #835bef66;
    color: white
}

.sidebar ul li .submenu ul li a:hover {
    background: transparent;
    color: white;
    text-decoration: none;
}


.sidebar li {
    text-align: left;
    margin-top: 10px;
    font-weight: 600;
    text-decoration: none;
    color: #635b5d;
    display: block;
    border-radius: 10px;
}

.sidebar li .active {
    color: white;
}

.sidebar ul li .submenu ul li .active {
    color: white;
    background: transparent;
    font-weight: 700;
  }