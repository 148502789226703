.progress {
  background: #120731;
  align-items: center;
  /* position: relative; */
  padding: 0 5px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.progress-value {
  animation: load 3s normal forwards;
  box-shadow: 0 10px 40px -10px #1c1751;
  border-radius: 100px;
  background: #1c1751;
  ;
  height: 5px;
  width: 2px;
}

@keyframes load {
  0% {
    width: 0px;
  }

  25% {
    width: 25%;
  }

  50% {
    width: 50%;
  }

  75% {
    width: 75%;
  }

  100% {
    width: 100%;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  height: 4px;
  width: 130px;
  --c: no-repeat linear-gradient(#6100ee 0 0);
  background: var(--c), var(--c), #d7b8fc;
  background-size: 60% 100%;
  animation: l16 3s infinite;
}

@keyframes l16 {
  0% {
    background-position: -150% 0, -150% 0
  }

  66% {
    background-position: 250% 0, -150% 0
  }

  100% {
    background-position: 250% 0, 250% 0
  }
}