.login_wrapper {
    width: 100%;
    height: 100vh;
    background: #120731;
    font-family: "Nunito Sans", sans-serif;
}

.login_wrapper .login_container {
    min-width: 380px;
    border-radius: 10px;
    background: #1c1751;
    padding: 24px;
}

.login_wrapper .login_container h3 {
    width: 100%;
    color: white;
    font-size: 28px;
    font-weight: 300;
}

.login_wrapper .login_container .row {
    width: 100%;
}

.login_wrapper .login_container label {
    display: block;
    margin-bottom: 5px;
    margin-left: 5px;
    font-weight: 300;
}

.login_wrapper .login_container .row {
    position: relative;
}

.login_container .row .passwordtoggleicon {
    position: absolute;
    top: 35px;
    right: 8px;
    color: white;
    cursor: pointer;
}

.login_wrapper .login_container .row input {
    width: 100%;
    background: #120731;
    border-radius: 4px;
    padding: 8px 12px;
    outline: none;
    font-size: 14px;
    text-align: left;
    color: white;
    border: none;
    max-width: 100%;
}

.login_wrapper .login_container .row label {
    color: white;
    font-weight: 300;
}

.login_wrapper .login_container .row button {
    color: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    background: #835bef;
    padding: 8px 12px;
    border: none;
    min-width: 100px;
}

.login_wrapper .login_container .row button:hover {
    background: #120731;
    cursor: pointer;
}


.loader1 {
    height: 15px;
    aspect-ratio: 2.5;
    --_g: no-repeat radial-gradient(farthest-side, #fff 90%, #0000);
    background: var(--_g), var(--_g), var(--_g), var(--_g);
    background-size: 20% 50%;
    animation: l43 1s infinite linear;
}

@keyframes l43 {
    0% {
        background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%
    }

    16.67% {
        background-position: calc(0*100%/3) 0, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%
    }

    33.33% {
        background-position: calc(0*100%/3) 100%, calc(1*100%/3) 0, calc(2*100%/3) 50%, calc(3*100%/3) 50%
    }

    50% {
        background-position: calc(0*100%/3) 50%, calc(1*100%/3) 100%, calc(2*100%/3) 0, calc(3*100%/3) 50%
    }

    66.67% {
        background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 100%, calc(3*100%/3) 0
    }

    83.33% {
        background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 100%
    }

    100% {
        background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%
    }
}