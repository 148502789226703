
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;

  &::-webkit-scrollbar {
    width: 2px;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: #120731;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #1c1751;
    border-radius: 20px;
  }

}



.buttonset button:active {
  /* set time duration to your needs */
  animation: gradient 100ms;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  transform: translateY(4px)
}

button:active {
  /* set time duration to your needs */
  animation: gradient 100ms;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  transform: translateY(4px)
}

.cover {
  position: relative;
  width: 100%;
  height: 100vh;

}

.home-section {
  min-height: 100vh;
  width: calc(100% - 255px);
  width: -moz-calc(100% - 255px);
  width: -webkit-calc(100% - 255px);
  transition: all 0.5s ease;
  display: flex;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #120731;


}

.rightdiv {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}