.dashboard_wrapper {
    width: 100%;
}

.dashboard_wrapper .dashboard_top .card_item {
    background-color: #1c1751;
    border-radius: 10px;
    padding: 28px 20px;
}


.dashboard_wrapper .dashboard_top .card_item .leftpart .card_icon_container {
    border-radius: 10px;
    max-width: 50px;
}

.dashboard_wrapper .dashboard_top .card_item .leftpart .icon1 {
    background-color: #3b2c81;
    color: #6a52dc;
}

.dashboard_wrapper .dashboard_top .card_item .leftpart .card_icon_container .dashboard_icon {
    font-size: 24px;
}

.dashboard_wrapper .dashboard_top .card_item .rightpart {
    text-align: right;
    font-size: 12px;
}

.dashboard_wrapper .dashboard_top .card_item .rightpart p {
    font-size: 18px;
    display: flex;
}

.dashboard_wrapper .dashboard_top .card_item .rightpart p span {
    display: inline-block;
    margin-right: 3px;
}

.dashboard_wrapper .dashboard_top .card_item .leftpart .icon2 {
    color: #20cc7a;
    background-color: #1d515f;
}

.dashboard_wrapper .dashboard_top .card_item .leftpart .icon3 {
    background-color: #612f60;
    color: #d9527d;
}

.dashboard_wrapper .dashboard_top .card_item .leftpart .icon4 {
    background-color: #673b48;
    color: #b46b42;
}

.dashboard_wrapper .line_chart_wrapper {
    background: #1c1751;
    border-radius: 10px;
    padding: 24px 12px;
}

.dashboard_wrapper .line_chart_wrapper h3 {
    font-size: 22px;
}

.dashboard_wrapper .multi_data_chart_wrapper {
    background: #1c1751;
    border-radius: 10px;
    padding: 24px 12px;
    width: 49%;
}

.dashboard_wrapper .multi_data_chart_wrapper h3 {
    font-size: 22px;
}

.dashboard_wrapper .donut_chart_wrapper {
    background: #1c1751;
    border-radius: 10px;
    padding: 24px 12px;
    width: 49%;
    margin-left: 11px;
}

.dashboard_wrapper .donut_chart_wrapper h3 {
    font-size: 22px;
}

.dashboard_wrapper .multi_data_chart_wrapper canvas {
    max-height: 350px !important;
}