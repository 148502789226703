/* Popup Css  */

button {
    background: #835bef;
    text-align: center;
    color: white;
    font-size: 16px;
    padding: 6px 12px;
    border-radius: 4px;
    border: 2px solid #835bef;
}

button:hover {
    background: #241e6b;
    cursor: pointer;
}

.create_popup_background {
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
}

.create_popup_container {
    min-width: 400px;
    background: #1c1751;
    padding: 16px 20px;
    border-radius: 8px;
    max-height: 98%;
    overflow-y: scroll;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

}

.create_popup_container .create_table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
}

.create_popup_container .popup_title p {
    font-size: 14px;
    cursor: pointer;
}

.create_popup_container .popup_title h2 {
    font-weight: 400;
    font-size: 22px;
}

.create_popup_container .create_table tr {
    width: 100%;
    margin: 0px auto;
}

.create_popup_container .create_table tr td {
    min-width: 200px;
    
}

.create_popup_container .create_table tr td input {
    color-scheme: dark;
}

.create_popup_container .create_table tr td input,
select {
    width: 100%;
    background: #120731;
    border-radius: 4px;
    padding: 8px 12px;
    outline: none;
    font-size: 14px;
    text-align: left;
    color: white;
    min-width: 170px;
    border: none;

    &::placeholder {
        color: white;
    }
}

.create_popup_container .create_table tr td select {
    text-align: center;
}

.create_popup_container .create_table tr td select option {
    padding: 8px 12px;
    border-radius: 4px;
}

.btn_container button {
    color: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    background: #835bef;
    padding: 8px 12px;
    border: none;
    min-width: 100px;
}

.btn_container button:hover {
    background: #120731;
    cursor: pointer;
}

.page_wrapper {
    margin: 20px auto;
    width: 98%;
    color: white;
}

.page_wrapper .content_wrapper {
    background: #1c1751;
    border-radius: 10px;
    padding: 20px 12px;
}


input,
select {
    width: 100%;
    background: #120731;
    border-radius: 4px;
    padding: 10px 12px;
    outline: none;
    font-size: 14px;
    text-align: left;
    color: white;
    min-width: 120px;
    border: none;
    color-scheme: dark;
    max-width: 250px;

    &::placeholder {
        color: white;
    }
}

select {
    text-align: center;
}

select:hover {
    cursor: pointer;
}

.options_table_container {
    max-width: 90%;
    margin: 0px auto;
}

.options_table {
    border-collapse: separate;
    border-spacing: 0 7px;
}

.options_table td {
    min-width: 100px;
}

.tran_table {
    font-size: 14px;
    text-align: left;
    display: block;
    max-width: 98%;
    margin: 2px auto 2px auto;
    padding: 10px 12px;
    overflow: scroll;
}

.tran_table table {
    width: 100%;
    padding: 20px 0px;
    text-align: left;
    overflow-x: scroll;
}

.tran_table table th {
    padding: 6px 0px;
    position: sticky;
    top: 0;
    z-index: 2;
    white-space: no-wrap;
    font-size: 16px;
    font-weight: 600;
    padding-right: 20px;
    background: #1c1751;
    min-width: 120px;
}

.tran_table table td {
    padding: 8px 3px;
    white-space: no-wrap !important;
    color: white;
    padding-right: 20px;
    min-width: fit-content;
}

.tran_table table td:hover {
    cursor: pointer;
}

.tran_table table .success p {
    color: #21c97b;
    font-weight: 600;
    border: 1px solid #21c97b;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    max-width: 80px;
}

.tran_table table .pending p {
    color: #ff8c33;
    font-weight: 600;
    border: 1px solid #ff8c33;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    max-width: 80px;
}

.tran_table table .failed p {
    color: #d9527d;
    font-weight: 600;
    border: 1px solid #d9527d;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    max-width: 80px;
}

.rowscount select {
    max-width: 100px;
}

.tran_table table .table_btn {
    padding: 5px 6px; 
    font-size: 14px;
}

#formpost {
    display:none;
}

.account_page_table {
    border-spacing: 0 20px;
}

.account_page_table td {
    min-width: 200px;
}




/* loading animation */
/* //animation load loda */

.loader-container {
    padding-top: 5%;
    display: flex;
    flex-direction: row;
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-item {
    display: inline-block;
    width: 2em;
    height: 2em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
    font-size: 30px;
    border: 8px solid #4361BA;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: 1s loader-animation linear infinite;
    position: relative;
}

@keyframes loader-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-success-completed {
    animation: none;
    border-bottom-color: none;
    border-color: #00b359;
    border-width: 3px;
    animation: fade-in-bck 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.loader-failure-completed {
    animation: none;
    border-bottom-color: none;
    border-color: #ff6666;
    border-width: 3px;
    animation: fade-in-bck 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.loader-check-icon {
    display: none;
}

.loader-check-icon-completed {
    font-size: 53px;
    display: block;
    color: #00b359;
    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.loader-failure-icon {
    display: none;
    /* font-size: 30px; */
}

.loader-failure-icon-completed {
    font-size: 53px;
    display: block;
    color: #ff6666;
    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fade-in-bck {
    0% {
        -webkit-transform: translateZ(80px);
        transform: translateZ(80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}




.superparentPopup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    /* color: white; */
    z-index: 8;
}

.superchildPopup {
    color: white;
    z-index: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 70%;
    text-align: center;
    transform: translate(-50%, -50%);
    display: flex;
    text-align: left;
    flex-shrink: 0;
    /* // flex-wrap: wrap; */
    align-items: center;
    margin: 10px;
    padding: 10px;
    background: #1c1751;
    z-index: 9;
    display:flex;
    justify-content: center;
}

.superchildPopup table {
    width: 100%;
}

.superchildPopup div,
label {
    /* background: #ffffff; */
}


.superchildPopup select {
    color: #6d7782;
    padding: 3px 5px;
    border-radius: 4px;
    /* // border: 1.5px solid #adadad; */
    text-align: center;
    min-width: 177px;
}